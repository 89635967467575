@if require-font('Work Sans') {

/* work-sans-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/work-sans-v18-latin-ext_latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-300italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/work-sans-v18-latin-ext_latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/work-sans-v18-latin-ext_latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/work-sans-v18-latin-ext_latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-500 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/work-sans-v18-latin-ext_latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-500italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/work-sans-v18-latin-ext_latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-600 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/work-sans-v18-latin-ext_latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-600italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/work-sans-v18-latin-ext_latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/work-sans-v18-latin-ext_latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-700italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/work-sans-v18-latin-ext_latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

} // end @if
